@import 'commonColors';

* {
    margin: 0
}

.container {
    padding-right: 1rem;
    padding-left: 1rem;
}

.link-color {
    color: $primary-color;

    &:hover {
        color: $secondary-color;
    }
}

.cursor-pointer {
    cursor: pointer;
}

//box sizing
.border-box {
    box-sizing: border-box;
}

/// background color classes 
.bg-color-primary {
    background-color: $primary-color !important;
}

.bg-color-secondary {
    background-color: $secondary-color !important;
}

.top-bar-background {
    background: linear-gradient(110deg, $primary-color 55%, $secondary-color 55%);
}

.bg-color-black {
    background-color: $default-Black;
}

.bg-color-white {
    background-color: $default-White;
}

.bg-color-light-blue {
    background-color: $lightBlue;
}

.bg-color-tertiary {
    background-color: $tertiary-color;
}

.bg-color-store {
    background-color: $store-bg-color;
}

.backgroundOverlay {
    position: relative;
}


.backgroundOverlay::before {
    content: '';
    background-color: #1b1b1b;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.banner-bg-overlay {
    position: relative;
    height: 300px;
}

.banner-bg-overlay::before {
    content: '';
    background-color: #1b1b1b;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.bg-with-hexImage {
    background-image: url('../images/hexImage.png');
    background-repeat: no-repeat;
    background-size: cover;
}



// text color classes
.text-color-white {
    color: $default-White;
}

.text-color-black {
    color: $default-Black;
}

.text-color-primary {
    color: $primary-color;
}

.text-color-secondary {
    color: $secondary-color;
}

.text-color-light-Black {
    color: $default-light-Black
}


// padding

.submit-btn-padding {
    padding: .75rem 15rem;
}

// stroke text 
.stroke-text {
    -webkit-text-stroke-color: red;
    -webkit-text-stroke-width: 1px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}




//border color classes

.br-color-secondary {
    border-color: $secondary-color !important;
}

.border-dashed {
    border: 2px dashed $light-tertiary-color;
    border-radius: 20px;
}




// font size 
.home-title {
    font-size: 55px !important;
}

.icon-size-20 {
    font-size: 20px;
}

.font-size-18 {
    font-size: 18px;
    line-height: 28px;
    text-align: justify;
}

.font-size-14 {
    font-size: 14px;
}



// sizing classes
.w-fit-content {
    width: fit-content;
}

.w-min-content {
    width: min-content;
}

.w-max-content {
    width: max-content;
}

.w-inherit {
    width: inherit;
}

.h-fit-content {
    height: fit-content;
}


// top bar 

#top-bar a {
    color: $default-White;
}


// navbar specific classes

.navlink-active {
    transition: all 0.2s ease-out;
    color: $secondary-color !important;
    font-weight: 600;
}

.nav-item a {
    color: $default-Black;
    font-size: 18px;
    text-decoration: none;

    &:hover {
        color: $primary-color !important;
        transition: all 0.2 ease-in-out;
    }
}

.nav-item .btn-navbar {
    padding: 7px 18px;
    font-size: 15px;
    font-weight: 600;
    background-color: $secondary-color;
    color: $default-White;
}

// carousel classes
.react-multiple-carousel__arrow {
    background-color: $light-tertiary-color !important;

    &::before {
        color: $primary-color !important;
    }
}

.react-multi-carousel-dot-list {
    bottom: -40px !important;
}

.react-multi-carousel-dot button {
    border: none !important;
    background-color: $light-tertiary-color;
}

.react-multi-carousel-dot--active button {
    background: $primary-color !important;
}


//image-sizing
.primary-icon {
    width: 75px;
}



// accordion css customize
.accordion {
    .accordion-item {
        border-radius: 16px;
    }

    .accordion-button {
        background: none !important;
        font-weight: 500 !important;

        &::after {
            background-image: url('../images/FAQ-plus-icon.png');
            width: 1.5rem;
            height: 1.5rem;
            background-size: cover;
            transition: transform .2s ease-in-out;
            ;
        }

        &:not(.collapsed) {
            &::after {
                background-image: url('../images//FAQ-minus-icon.png');
            }

            box-shadow: none;
            color: $default-Black;
        }

        &:focus {
            border: none !important;
            box-shadow: none !important;
        }
    }

}

// modal pop up

.modal-dialog {
    transform: scale(.8) translate(0px, -80px) !important;
}

//common  input styling

.common-bordered-input {
    background-color: transparent;
    border: 2px solid #D9D9D9;

    input {
        background-color: transparent;
        border: none;

        &:focus {
            outline: 0;
            box-shadow: none !important;
            background: none !important;
            border: none;
        }

        &::placeholder {
            font-size: 1rem !important;
        }
    }
}

//textArea styling

.textarea {
    background-color: transparent;
    border: 2px solid #D9D9D9;
    padding: 1rem 1.3rem;
    border-radius: .25rem;

    &:focus {
        // outline: 0;
        box-shadow: none !important;
        background: none !important;
        // border: none;
    }

    &::placeholder {
        font-size: 1rem !important;
    }
}

// store page customize

#findAStore {
    .search-input {
        border: 1px solid #D9D9D9;
        background: $default-White;

        input {
            border: none;
            background: transparent;

            &:focus {
                outline: 0;
                border: none;
                box-shadow: none;
            }

            &::placeholder {
                font-size: .9rem;
            }
        }
    }
}

.terms-condition {
    li a {
        &:hover {
            color: $default-White !important;
        }
    }
}

//footer
#footer {
    .menu-item ul {
        li a {
            font-size: 18px;
        }

        li a {
            color: $default-White !important;

            &:hover {
                color: $default-White !important;
            }
        }
    }

}

#store-maps {
    .store-card {
        border-radius: 15px;
        overflow: hidden;
        background-color: #fafafa;
    }

    .store-card-img {
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        min-width: 120px;
    }

}

// alert warning
.alert-warning {
    padding: .15rem;
    color: $warning-color;
    background: none;
    border: none;

    .btn-close {
        width: auto;
        height: auto;
    }
}

@media only screen and (min-width: 765px) and (max-width: 995px) {
    .menu-item {
        ul {
            gap: 1rem !important;
        }
    }

    .footer-menu li a {
        font-size: 14px;
    }
}

@media only screen and (min-width: 565px) and (max-width: 995px) {

    /* floating section styles */
    .floating-section>.child-section {
        top: 0 !important;
        margin-bottom: 20px;
    }

    .w-75 {
        width: 100% !important;
    }

    .retailer-section-description {
        width: 100% !important;
    }
}

@media only screen and ((min-width: 768px) and (max-width : 992px)) {
    #store-maps {
        .store-card {
            border-radius: 15px;
            overflow: hidden;
            background-color: #fafafa;
        }

        .store-card-description {
            // width: 70% !important;
        }

        .font-size-14 {
            font-size: 13px;
        }

        .store-card-img {
            // height: 100%;
            display: none;
            // width: 100px !important;
            // height: 100% !important;
        }
    }
}

@media only screen and (max-width:565px) {
    .primary-icon {
        width: 40px;
        margin-bottom: 0 !important;
    }

    //responsive navbar 
    .nav-item .btn-navbar {
        background-color: transparent;
        color: $default-Black;
        font-size: 18px;
        font-weight: normal;
        padding: 0;
    }

    .navbar-nav {
        align-items: flex-start !important;
        padding: 10px 15px;
    }

    .navbar-toggler {
        font-size: .95rem;
    }

    // padding resizing
    .px-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .py-5 {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    //managing with of fonts on FOR OUR CUSTOMER Section
    .w-75 {
        width: 100% !important;
    }

    // font resizing
    .display-6 {
        font-size: 1.5rem;
    }

    .fs-6 {
        font-size: .9rem !important;
    }

    .fs-5 {
        font-size: .9rem !important;
        line-height: 25px !important;
    }

    .fs-1 {
        font-size: 1.6rem !important;
    }

    .icon-size-20 {
        font-size: 16px !important;
    }

    .font-size-18 {
        font-size: 14px;
        line-height: inherit;
    }

    // banner image absolute icon
    .bannerImg-icon {
        top: 10px !important;
        left: 40px !important;
        padding: 10px;
        box-sizing: border-box;

        img {
            width: 70%;
        }
    }

    //common banner section
    .banner-bg-overlay {
        height: auto;
    }

    // common title 
    .line--below-title {
        line-height: 0;
    }

    // about section image
    .common-image-section {
        .img-movable-back {
            left: -8px !important;
        }
    }



    //common  input styling

    .common-bordered-input {
        input {
            padding: .25rem .35rem;

            &::placeholder {
                font-size: .75rem !important;
            }
        }
    }

    //textArea styling

    .textarea {
        padding: .5rem .75rem;

        &::placeholder {
            font-size: .85rem !important;
        }
    }

    // floating section 
    .floating-section>.child-section {
        top: 0 !important;
        margin-bottom: 20px;
    }


    //padding
    .submit-btn-padding {
        padding: .5rem 5rem;
    }

    // accordion css customize 

    .accordion {
        .accordion-item {
            border-radius: 16px;
        }

        .accordion-body {
            font-size: .75rem;
        }

        .accordion-button {
            background: none !important;
            font-weight: 500 !important;
            font-size: .75rem;

            &::after {
                width: 1rem;
                height: 1rem;
            }
        }

    }

    .border-dashed {
        border: 1.5px dashed $light-tertiary-color;
        border-radius: 15px;
    }

    //carousel
    .retailer-section-description {
        width: 100% !important;
    }



    // store

    #store-maps {
        .store-heading {
            font-size: 1rem;
        }

        .store-card {
            border-radius: 15px;
            overflow: hidden;
            background-color: #fafafa;
        }

        .store-card-img {
            width: 100%;
            height: 200px !important;

        }
    }

    //footer
    #footer {
        .footer-social-icon {
            font-size: 30px;
        }

        .social-icon-box {
            justify-content: left !important;
            margin-top: 10px;
            // margin-bottom: 10px;
        }

        .menu-item ul {
            flex-direction: column;
            gap: 1rem !important;

            li a {
                font-size: 14px;
            }

            li a {
                color: $default-White !important;

                :hover {
                    color: $default-White !important;
                }
            }
        }

        .copyright-text {
            text-align: center;
        }

        .terms-data ul {
            padding-top: 5px;

            li a {
                font-size: 14px;
            }
        }
    }
}